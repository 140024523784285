import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'

import things_logo from '../../assets/images/projects/service/tech/things-logo.jpg'

const ServiceDesignKCPrivate = props => (
  <Layout>
    <Helmet>
      <title>Keycrime</title>
      <meta
        name="Service Design - Keycrime"
        content="Redesigning web application for criminal investigation"
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.kccover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">INTERACTION DESIGN</span>
            <span className="tag">DIGITAL UX</span>
            <span className="tag">DIGITAL UI</span>
            <h1>keycrime.</h1>
            <h3>
              We redesigned an operative and analytical web application of
              Predictive Policing that improved the workflow efficiency &
              effectiveness of police officers. It allowed them to fully focus
              on the investigation and predict when, where and how a criminal is
              likely to hit next, thus improving their safety and optimising the
              dispatch of resources.
            </h3>
          </header>
          <section className="header-post">
            <div className="headerpost">
              <div className="headerpost-left">
                <p>
                  * This project was carried out while working at the design
                  agency, THINGS, in Milan.
                </p>
                <p>
                  <a href="/say-hello">
                    <u>Get in touch</u>
                  </a>
                  with me for more details related to this project; such as my
                  role, the problems i faced, the processes i used and how i
                  addressed the challenges.
                </p>
              </div>
              <div className="headerpost-right">
                <div className="client-image">
                  <img className="span-4" src={things_logo} alt="things logo" />
                </div>
                <p>
                  Things.is a design and innovation agency for the Internet of
                  Things. We create products & services with their own
                  experiential ecosystems outlined by multi-channel touch
                  points. We envision products and services in the IoT era
                  through a human-centred approach. www.things.is
                </p>
              </div>
            </div>
          </section>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  Keycrime was created and developed to fight robberies in the
                  metropolitan area of Milan. It combines the investigation and
                  analytical experience of police officers with complex
                  algorithms to provide them with accurate crime forecasting,
                  allowing them to track criminal behaviour and catch them
                  before the next crime.
                </p>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2018 - 2019
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 5 months
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Redesigning a web application
                  and a dashboard for criminal investigation.
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Design team of THINGS, Milan
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Researching of inspiration on UX &
                  UI elements, mapping of Information Architecture, wireframing
                  of screens, writing script and sketching storyboard for video,
                  visual design consultation and prototyping of application on
                  Adobe XD.
                </span>
                {/* <div className="challenge-client">
                  <h5>CLIENT</h5>
                  <div className="client-image">
                    <div className="span-4">
                      <Img
                        fluid={props.data.keycrime_logo.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="image">
              <Img
                fluid={props.data.kcchallenge.childImageSharp.fluid}
                alt=""
                title=""
              />
            </div>
            <p className="full-text">
              Our aim was to redesign the interaction, user experience and
              visual design of the existing web application so that all its
              product functionalities are easy to use. This improvement in the
              workflow will also allow for the product to be scaled and used by
              police officers in different cities in the world.
            </p>
          </section>
          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>process</h2>
          </section>
          <section className="private">
            <div className="inner">
              <div className="private-logo" />
              <h3>This project is private.</h3>
              <p className="private-text">
                get in touch with me on <u>sagar.satam25@gmail.com</u> <br />
                to discuss about this project and get access to view the entire
                project.
              </p>
            </div>
          </section>
          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to
              <br />
              <Link to="/work">all my work.</Link>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/service-design-hive-part-1">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">hive (part 1)</div>
                <div className="text">
                  Unique experiences that bring people together over home cooked
                  meals hosted by local chefs in neighbourhoods.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>UX STRATEGY</li>
                    <li>BUSINESS DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-muvisi-private">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">muvisi</div>
                <div className="text">
                  A mobility service that makes it stress-free & convenient to
                  find parking spots in the city.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>DIGITAL UX/UI</li>
                    <li>INTERACTION DESIGN</li>
                    <li>BRANDING</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ServiceDesignKCPrivate

export const fluidKCPrivateImage = graphql`
  fragment fluidKCPrivateImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    kccover: file(
      relativePath: {
        eq: "projects/service/keycrime/cover/keycrime-cover-image-blurred.jpg"
      }
    ) {
      ...fluidKCPrivateImage
    }
    things_logo: file(
      relativePath: { eq: "projects/service/tech/things-logo.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    keycrime_logo: file(
      relativePath: { eq: "projects/service/keycrime/keycrime-logo.png" }
    ) {
      ...fluidKCPrivateImage
    }
    kcchallenge: file(
      relativePath: { eq: "projects/service/keycrime/challenge/1.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess2: file(
      relativePath: { eq: "projects/service/keycrime/process/2.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess3: file(
      relativePath: { eq: "projects/service/keycrime/process/3.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess4: file(
      relativePath: { eq: "projects/service/keycrime/process/4.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess5: file(
      relativePath: { eq: "projects/service/keycrime/process/5.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess6: file(
      relativePath: { eq: "projects/service/keycrime/process/6.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess7: file(
      relativePath: { eq: "projects/service/keycrime/process/7.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess8: file(
      relativePath: { eq: "projects/service/keycrime/process/8.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess9: file(
      relativePath: { eq: "projects/service/keycrime/process/9.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess10: file(
      relativePath: { eq: "projects/service/keycrime/process/10.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess11: file(
      relativePath: { eq: "projects/service/keycrime/process/11.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess12: file(
      relativePath: { eq: "projects/service/keycrime/process/12.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess13: file(
      relativePath: { eq: "projects/service/keycrime/process/13.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess14: file(
      relativePath: { eq: "projects/service/keycrime/process/14.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess15: file(
      relativePath: { eq: "projects/service/keycrime/process/15.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess16: file(
      relativePath: { eq: "projects/service/keycrime/process/16.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess17: file(
      relativePath: { eq: "projects/service/keycrime/process/17.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess18: file(
      relativePath: { eq: "projects/service/keycrime/process/18.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess19: file(
      relativePath: { eq: "projects/service/keycrime/process/19.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess20: file(
      relativePath: { eq: "projects/service/keycrime/process/20.png" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess21: file(
      relativePath: { eq: "projects/service/keycrime/process/21.png" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess22: file(
      relativePath: { eq: "projects/service/keycrime/process/22.png" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess23: file(
      relativePath: { eq: "projects/service/keycrime/process/23.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcprocess24: file(
      relativePath: { eq: "projects/service/keycrime/process/24.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcoutcome25: file(
      relativePath: { eq: "projects/service/keycrime/outcome/25.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcoutcome26: file(
      relativePath: { eq: "projects/service/keycrime/outcome/26.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    kcoutcome27: file(
      relativePath: { eq: "projects/service/keycrime/outcome/27.jpg" }
    ) {
      ...fluidKCPrivateImage
    }
    project1: file(relativePath: { eq: "projects/10.png" }) {
      ...fluidKCPrivateImage
    }
    project2: file(relativePath: { eq: "projects/19.jpg" }) {
      ...fluidKCPrivateImage
    }
  }
`
